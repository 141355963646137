.da-spinner-container { 
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px;
}

.da-details-list-container {
	margin: 20px 0;
}

.da-grid-wrapper {
	position: relative;
}

.da-grid-wrapper .da-grid-overlay {
	background-color: rgba(255, 255, 255, 0.8);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.da-error-messages-command-bar {
	color: var(--warning); 
	margin-right: 1em;
}

.da-error-messages {
	color: var(--warning); 
	margin-top: 1em;
	line-height: 1.75em;
	word-wrap: normal;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.da-error-messages span {
	padding-right: 1em;
}

.rdg-cell {
	box-shadow: none;
}

.job-link:hover {
	text-decoration: underline !important;
}