.jobs-summary-grid .ms-DetailsHeader-cellName[id$='hours_budget-name'],
.jobs-summary-grid .ms-DetailsHeader-cellName[id$='hours_booked-name'],
.jobs-summary-grid .ms-DetailsHeader-cellName[id$='hours_consumed-name'],
.jobs-summary-grid .ms-DetailsHeader-cellName[id$='hours_this_month-name'],
.jobs-summary-grid .ms-DetailsHeader-cellName[id$='hours_next_month-name'],
.jobs-summary-grid .ms-DetailsHeader-cellName[id$='overall_rag-name'],
.jobs-summary-grid .ms-DetailsHeader-cellName[id$='scope_rag-name'],
.jobs-summary-grid .ms-DetailsHeader-cellName[id$='schedule_rag-name'],
.jobs-summary-grid .ms-DetailsHeader-cellName[id$='budget_rag-name'],
.jobs-summary-grid .ms-DetailsHeader-cellName[id$='status_date-name']{
  transform: rotate(180deg);
  writing-mode: vertical-rl;
}

.jobs-summary-grid .ms-DetailsHeader {
  height: 87px;
}

.jobs-summary-grid .ms-DetailsRow-cell {
  padding-left: 6px !important;
}

.jobs-summary-grid .ms-DetailsRow-cell:first-child .ms-Link {
  white-space: nowrap;
  width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.jobs-summary-grid .ms-DetailsHeader-cellTitle {
  align-items: center;
  padding: 0;
}

.jobs-summary-grid .ms-DetailsHeader-cell,
.jobs-summary-grid .ms-DetailsHeader-cell *:not(i) {
  height: 100% !important;
}

.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='name'],
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='name'] + *,
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='project_lead_id'],
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='project_lead_id'] + *,
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='account_manager_id'],
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='account_manager_id'] + *,
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='project_manager_id'],
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='project_manager_id'] + *,
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='statusText'],
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='statusText'] + * {
  height: auto !important;
}

.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='project_lead_id'],
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='project_lead_id'] + *,
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='account_manager_id'],
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='account_manager_id'] + *,
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='project_manager_id'],
.jobs-summary-grid .ms-DetailsHeader-cellTitle[id$='project_manager_id'] + * {
  padding: 0;
}