.grid-container {
  margin-top: 1em;
}

.grid-container .react-grid-Cell.rdg-last--frozen {
  box-shadow: none !important;
}

.grid-container .react-grid-Cell,
.react-grid-Canvas,
.react-grid-Main,
.react-grid-Grid {
  background-color: transparent;
}

.rdg-row:hover .rdg-cell {
  background: var(--white);
  color: var(--neutralPrimary);
  cursor: pointer;
}

.rdg-row .rdg-cell .with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rdg-row .rdg-cell .with-actions .cell-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.grid-container.default .react-grid-Header .react-grid-HeaderCell,
.grid-container.default .grid-row .rdg-row {
  background: var(--white);
  color: var(--neutralPrimary);
}
.grid-container.default
  .grid-row.weekend
  .react-grid-Row:hover
  .react-grid-Cell {
  color: var(--neutralPrimary);
}

.grid-container.dark .react-grid-Header .react-grid-HeaderCell,
.grid-container.dark .grid-row .rdg-row {
  background: var(--darkBg);
  color: var(--darkTextColor);
}
.grid-container.dark .grid-row.weekend .react-grid-Row:hover .react-grid-Cell {
  color: var(--darkTextColor);
}

.grid-container.contrast .react-grid-Header .react-grid-HeaderCell,
.grid-container.contrast .grid-row {
  background: var(--black);
  color: var(--darkTextColor);
}
.grid-container.contrast
  .grid-row.weekend
  .react-grid-Row:hover
  .react-grid-Cell {
  color: var(--darkTextColor);
}

.grid-container .grid-row.committed .rdg-row {
  background: var(--committed);
  color: var(--darkBg);
}
.grid-container.dark
  .grid-row.committed
  .react-grid-Row:hover
  .react-grid-Cell {
  color: var(--darkBg);
}

.grid-container .grid-row.tentative .rdg-row {
  background: var(--tenative);
  color: var(--neutralDark);
}
.grid-container.dark
  .grid-row.tentative
  .react-grid-Row:hover
  .react-grid-Cell {
  color: var(--neutralDark);
}

.react-grid-Header,
.react-grid-HeaderCell,
.da-page .da-details-list-container {
  background: transparent;
}

.grid-container * {
  box-sizing: border-box;
}

.grid-container .grid-row.weekend .rdg-cell {
  background-color: rgba(0, 0, 0, 0.05);
}

.grid-container .rdg-cell-action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
