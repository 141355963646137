.da-bookings-timeline .ms-DetailsHeader {
  height: 100px;
}
.da-bookings-timeline .ms-DetailsList-headerWrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.da-bookings-timeline.da-details-list-container {
  height: 50vw;
}
.da-bookings-timeline .ms-DetailsHeader-cell:first-child {
  height: 100%;
}
.da-bookings-timeline .ms-DetailsHeader-cell:first-child > * {
  top: unset;
}
.da-bookings-timeline .ms-DetailsHeader-cell:not(:first-child) {
  transform: rotate(-90deg) translateX(-60px);
}
.da-bookings-timeline .ms-DetailsHeader-cell:not(:first-child):hover {
  background-color: transparent;
}
.da-bookings-timeline
  .ms-DetailsHeader-cell:not(:first-child)
  .ms-DetailsHeader-cellTitle {
  overflow: visible;
}
.da-bookings-timeline
  .ms-DetailsHeader-cell:not(:first-child)
  .ms-DetailsHeader-cellName {
  overflow: visible;
  padding-left: 12px;
  padding-right: 50px;
}

.da-bookings-timeline .header-default .ms-DetailsRow-cell.weekend-col {
  background-color: rgb(242, 242, 242);
}

.da-bookings-timeline .header-dark .ms-DetailsRow-cell.weekend-col {
  background-color: rgb(14, 13, 12);
}

.da-bookings-timeline .header-contrast .ms-DetailsRow-cell.weekend-col {
  background-color: rgb(0, 0, 0);
}

.da-bookings-timeline .ms-DetailsRow-cell:not(:first-child) {
  display: inline-flex;
  padding: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px !important;
}

.da-bookings-timeline .ms-DetailsRow-cell .timesheet-warning {
  padding: 4px;
  background-color: #000;
}

.da-bookings-timeline .ms-DetailsRow-cell:not(:first-child) > * {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
  text-align: center;
}

.da-bookings-timeline .ms-DetailsRow-cell:not(:first-child) > div > span {
  display: inline-flex;
  width: 100%;
  height: 95%;
  justify-content: center;
  align-items: center;
}

.da-legend {
  padding: 1em;
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}
.da-legend.default {
  background-color: rgb(255, 255, 255);
}
.da-legend.dark {
  background-color: rgb(27, 26, 25);
}
.da-legend.contrast {
  background-color: rgb(0, 0, 0);
}
.da-legend > * {
  margin-right: 0.5em;
}

.da-legend .dot {
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 100%;
  margin-left: 0.5em;
}

.da-bookings-timeline {
  height: 100%;
  position: relative;
}

.da-bookings-timeline .ms-DetailsRow-cell:first-child,
.da-bookings-timeline .ms-DetailsHeader-cell:first-child {
  position: sticky;
  inset: 0;
  z-index: 500;
}
.da-bookings-timeline.grid-default .ms-DetailsRow-cell:first-child,
.da-bookings-timeline.grid-default .ms-DetailsHeader-cell:first-child {
  background-color: rgb(255, 255, 255);
}
.da-bookings-timeline.grid-dark .ms-DetailsRow-cell:first-child,
.da-bookings-timeline.grid-dark .ms-DetailsHeader-cell:first-child {
  background-color: rgb(27, 26, 25);
}
.da-bookings-timeline.grid-contrast .ms-DetailsRow-cell:first-child,
.da-bookings-timeline.grid-contrast .ms-DetailsHeader-cell:first-child {
  background-color: rgb(0, 0, 0);
}

.da-client-timesheets-list .ms-GroupHeader > div:first-child > div:first-child {
  display: none;
}

.da-client-timesheets-list .ms-DetailsRow > div:first-child {
  width: 0;
}

.ms-GroupHeader-title > span:last-child {
  display: none;
}

.ms-Panel-commands {
  background-color: #fff;
  z-index: 2;
}