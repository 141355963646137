.da-job-budget {
  position: relative;
}

.da-job-budget .da-job-budget-header h2 {
  font-weight: 100;
  margin: 0;
  margin-right: 1em;
}

.da-job-budget .da-job-budget-header a {
  color: var(--themePrimary);
  text-decoration: none;
  cursor: pointer;
}

.da-job-budget .da-job-budget-header a:hover {
  text-decoration: underline;
}

.da-client-job-budget-item {
  margin-bottom: 1rem;
}
