@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

.da-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.da-main > main {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: auto;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.da-main > main .da-sidebar .ms-Nav-group {
  overflow: hidden;
}

.da-main > main .da-sidebar {
  display: none;
  flex-direction: column;
  min-width: 14.286em;
  border-right: 1px solid var(--neutralLight);
  overflow-y: auto;
}

.da-main > main .da-stage {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.da-main > main .da-general-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0;
  animation: fadeIn 250ms 500ms ease-out forwards;
}

.da-main .ms-Nav-navItem button.ms-Button {
  pointer-events: none;
}


.da-main .ms-Nav-navItem button.ms-Nav-chevronButton{
  cursor: pointer;
  width: 100%;
}

.da-main .ms-Nav-navItem button.ms-Nav-chevronButton > i {
  transform: rotate(-90deg);
}

.da-main .ms-Nav-navItem .is-expanded button.ms-Nav-chevronButton > i {
  transform: rotate(0deg);
}

@media screen and (min-width: 1024px) {
  .da-main > main .da-sidebar {
    display: flex;
  }
}

.da-main.da-teams .da-sidebar {
  display: none;
}

.da-main.da-teams .da-teams--nav {
  display: block;
}

.da-cell-align-center {
  justify-content: center;
}

.da-cell-align-right {
  justify-content: end;
}

@media screen and (min-width: 600px) {
  .da-main.da-teams .da-teams--nav {
		display: none;
  }
	.da-main.da-teams .da-sidebar {
		display: block;
	}
}
