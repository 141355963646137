.courses-table-wrapper .ms-DetailsHeader {
  padding: 0;
}

.courses-table-wrapper .ms-DetailsHeader .ms-DetailsHeader-cell {
  padding: 0;
}

.courses-table-wrapper .ms-DetailsHeader > .ms-DetailsHeader-cell:nth-child(n+2) span {
  display: block !important;
  text-align: center;
  padding: 0;
}

.courses-table-wrapper .ms-DetailsRow-fields > .ms-DetailsRow-cell:nth-child(n+2) {
  display: block !important;
  text-align: center;
  padding: 11px 0;
}
