.da-command-bar,
.da-command-bar-hidden {
  display: flex;
  align-items: center;
  min-height: 60px;
  width: 100%;
  margin-bottom: 2em;
  padding: 0 24px;
  box-sizing: border-box;
  z-index: 100;
}

.da-command-bar {
  border-bottom: 1px solid var(--neutralLight);
  /* background-color: var(--neutralLighter); */
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.da-command-bar .ms-Breadcrumb {
  margin: 0;
  margin-right: 1em;
}

.da-command-bar .ms-Breadcrumb .ms-Breadcrumb-itemLink,
.da-command-bar .ms-Breadcrumb .ms-Breadcrumb-item {
  font-size: 1em;
}

.da-command-bar button {
  flex: none;
}

.da-command-bar--breadcrumbs {
  display: flex;
  flex-grow: 1;
}

.da-command-bar--breadcrumbs > div {
  width: 100%;
}
