.da-navheader {
	align-items: center;
	background-color: var(--themePrimary);
	display: flex;
	flex: none;
	min-height: 3.429em;
	position: relative;
	z-index: 2;
}

.da-navheader .da-mobile-only {
	display: block;
}

.da-navheader .ms-Button {
	color: var(--white);
}

.da-navheader .ms-Button:hover {
	background-color: var(--themeDarkAlt);
}

.da-navheader .da-nav-menu {
	display: inline-block;
}

.da-navheader .da-logo-container {
	flex: none;
	overflow: hidden;
	display: flex;
	align-items: center;
	width: 32px;
	margin-left: 1em;
}

.da-navheader .da-logo-container img {
	height: 1.429em;
	padding: 0;
}

.da-navheader .da-vertical-separator {
	align-self: stretch;
	background-color: var(--white);
	flex: none;
	margin: 1em 0;
	opacity: 0.3;
	width: 1px;
	display: none;
}

.da-navheader .da-product-title {
	align-items: center;
	color: white;
	flex: none;
	font-size: 1em;
	line-height: 3.429em;
	padding: 0 0.857em;
}

.da-navheader .da-spacer {
	flex: auto;
}

.da-navheader .ms-Button {
	height: 100%;
	border-radius: 0;
}

.da-navheader .da-persona {
	display: flex;
	align-self: stretch;
	align-items: center;
	flex: none;
	padding: 0 0.5em;
}

.da-client-panel {
	padding: 1em 0;
}

@media screen and (min-width: 1024px) {
	.da-navheader .da-mobile-only {
		display: none;
	}

	.da-navheader .da-nav-menu {
		display: none;
	}

	.da-navheader .da-logo-container {
		width: auto;
		margin-left: 0;
	}
	
	.da-navheader .da-logo-container img {
		height: 1.429em;
		padding: 0 1.143em;
	}

	.da-navheader .da-vertical-separator {
		display: block;
	}
}