.da-container {
	max-width: 90%;
	width: 100%;
	padding: 0 5vw;
	margin: 0 auto 2em auto;
	height: 100%;
	overflow: hidden;
}

@media screen and (min-width: 600px) {
	.da-container {
		max-width: 80%;
	}
}

@media screen and (min-width: 1366px) {
	.da-container {
		max-width: 1100px;
	}
}

.da-container > header {
	display: flex;
	flex-direction: row;
	margin-bottom: 1.5em;
	align-items: center;
}

.da-container > header h2 {
	font-weight: 500;
	flex: auto;
	margin: 0;
}
