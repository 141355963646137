.no-access {
  box-shadow: var(--boxshadow);
  padding: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-access span {
  color: var(--neutralSecondary);
  white-space: normal;
}
