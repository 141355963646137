.da-page {
  box-sizing: border-box;
  display: flex;
  flex: auto;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 40px;
  position: relative;
}

.da-page .da-details-list-container {
  display: block;
  box-shadow: var(--boxshadow);
  background-color: var(--white);
  position: relative;
}

.da-page .da-details-list-container .ms-Viewport .ms-DetailsHeader {
  padding-top: 1px;
}

.da-page .da-details-list-container .ms-Viewport .ms-DetailsRow {
  cursor: pointer;
}

.da-page .da-details-list-container .ms-DetailsRow-cell {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.da-page .da-details-list-container .ms-Toggle {
  margin-bottom: 0;
}

.da-page .da-details-list-container .da-details-list-header {
  display: flex;
  padding: 0.25em 1em;
  flex-direction: row;
  min-height: 45px;
  align-items: center;
  border-bottom: 1px solid var(--neutralLighter);
}

.da-page .da-details-list-container .da-details-list-header > * {
  margin-right: 1em;
}

.da-page .da-details-list-container .da-details-list-header > *:last-child {
  margin-right: 0;
}

.da-page
  .da-details-list-container
  .da-details-list-header
  .da-details-list-title {
  font-weight: 100;
  margin: 0;
  margin-right: 1em;
}

ul.da-unordered-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.da-unordered-list li {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

ul.da-unordered-list li:last-child {
  margin-bottom: 0;
}

ul.da-unordered-list li h4 {
  font-weight: 600;
  width: 150px;
  margin: 0;
}

ul.da-unordered-list li p {
  margin: 0;
}

.da-page .da-tabs {
  display: flex;
  flex-direction: row;
}

.da-page .da-form {
  display: flex;
  flex-direction: column;
}

.da-page .da-form.padded {
  padding: 1em;
}

.da-page .da-form > * {
  margin-bottom: 0.5em;
}

.da-page .da-form > *:last-child {
  margin-bottom: 0;
}

.da-page .da-form .ms-Toggle {
  margin: 0;
}

.da-page .da-form .ms-Toggle .ms-Toggle-innerContainer {
  height: 30px;
  display: flex;
  align-items: center;
}

.da-page .da-form .ms-DatePicker .ms-TextField-fieldGroup:before {
  content: '';
}