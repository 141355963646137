.opportunities-grid .ms-DetailsHeader-cellName[id$='forecast-name'],
.opportunities-grid .ms-DetailsHeader-cellName[id$='close_date-name'],
.opportunities-grid .ms-DetailsHeader-cellName[id$='start_date-name'],
.opportunities-grid .ms-DetailsHeader-cellName[id$='account_manager_name-name'],
.opportunities-grid .ms-DetailsHeader-cellName[id$='business_unit_name-name'],
.opportunities-grid .ms-DetailsHeader-cellName[id$='solution_names-name'],
.opportunities-grid .ms-DetailsHeader-cellName[id$='industry_name-name'] {
  transform: rotate(180deg);
  writing-mode: vertical-rl;
}

.opportunities-grid .ms-DetailsHeader {
  height: 97px;
}

.opportunities-grid .ms-DetailsHeader-cellTitle {
  align-items: center;
  padding: 0px;
  width: 42px;
}

.opportunities-grid .ms-DetailsHeader-cell,
.opportunities-grid .ms-DetailsHeader-cell *:not(i) {
  height: 100% !important;
  line-height: 20px;
}

.opportunities-grid .ms-DetailsHeader-cellTitle[id$='name'],
.opportunities-grid .ms-DetailsHeader-cellTitle[id$='client'],
.opportunities-grid .ms-DetailsHeader-cellTitle[id$='name'] + *,
.opportunities-grid .ms-DetailsHeader-cellTitle[id$='value'],
.opportunities-grid .ms-DetailsHeader-cellTitle[id$='value'] + *,
.opportunities-grid .ms-DetailsHeader-cellTitle[id$='stage'],
.opportunities-grid .ms-DetailsHeader-cellTitle[id$='stage'] + * {
  height: auto !important;
}
