
.da-client-timesheets-list .ms-DetailsHeader-cell:not(:nth-child(-n+2)):hover {
  background-color: transparent;
}

.da-client-timesheets-list .header-default .ms-DetailsRow-cell.weekend-col {
  background-color: rgb(242, 242, 242);
}

.da-client-timesheets-list .header-dark .ms-DetailsRow-cell.weekend-col {
  background-color: rgb(14, 13, 12);
}

.da-client-timesheets-list .header-contrast .ms-DetailsRow-cell.weekend-col {
  background-color: rgb(0, 0, 0);
}

.da-client-timesheets-list.grid-default .ms-DetailsRow-cell:nth-child(-n+2),
.da-client-timesheets-list.grid-default .ms-DetailsHeader-cell:nth-child(-n+2) {
  background-color: rgb(255, 255, 255);
}
.da-client-timesheets-list.grid-dark .ms-DetailsRow-cell:nth-child(-n+2),
.da-client-timesheets-list.grid-dark .ms-DetailsHeader-cell:nth-child(-n+2) {
  background-color: rgb(27, 26, 25);
}
.da-client-timesheets-list.grid-contrast .ms-DetailsRow-cell:nth-child(-n+2),
.da-client-timesheets-list.grid-contrast .ms-DetailsHeader-cell:nth-child(-n+2) {
  background-color: rgb(0, 0, 0);
}
