:root {
  --themePrimary: #f15a29;
  --themeLighterAlt: #fef8f6;
  --themeLighter: #fde4db;
  --themeLight: #fbccbd;
  --themeTertiary: #f79b7c;
  --themeSecondary: #f46e42;
  --themeDarkAlt: #da5225;
  --themeDark: #b8461f;
  --themeDarker: #883317;
  --neutralLighterAlt: #faf9f8;
  --neutralLighter: #f3f2f1;
  --neutralLight: #edebe9;
  --neutralQuaternaryAlt: #e1dfdd;
  --neutralQuaternary: #d0d0d0;
  --neutralTertiaryAlt: #c8c6c4;
  --neutralTertiary: #a19f9d;
  --neutralSecondary: #605e5c;
  --neutralPrimaryAlt: #3b3a39;
  --neutralPrimary: #323130;
  --neutralDark: #201f1e;
  --black: #000000;
  --white: #ffffff;
  --warning: #ff2f2f;
  --invoiced: #f7d9fc;
  --occurred: #cee1ff;
  --committed: #cbfaca;
  --tenative: #ffe4ce;
  --blue: #ffe4ce;
  --red: rgb(241, 41, 41, 0.25);
  --orange: rgba(241, 90, 41, 0.25);
  --green: rgba(41, 241, 58, 0.25);
  --yellow: rgba(241, 238, 41, 0.25);
  --purple: rgba(201, 41, 241, 0.25);
  --blue: rgba(44, 137, 244, 0.3);
  --grey: rgba(60, 60, 60, 0.3);
  --darkBg: #1b1a19;
  --darkTextColor: #f3f2f1;
  --boxshadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
  /* background-color: var(--neutralLighterAlt); */
}

body {
  margin: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 88%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
