.skill-ratings-grid .rdg .row-dirty {
  font-style: italic;
}

.skill-ratings-grid .rdg .member-column {
  padding: 0 8px;
}

.skill-ratings-grid .rdg {
  height: 720px;
}

.skill-ratings-grid .rdg-cell-drag-handle {
  margin: unset;
}

.skill-ratings-grid .cell-expander {
  float: right;
  float: inline-end;
  display: flex;
  gap: 10px;
  block-size: 100%;
  margin-right: 8px;
  cursor: pointer;
}

.skill-ratings-grid .cell-expander > span {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.skill-ratings-grid .rdg-cell-value a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.skill-ratings-grid .rdg-cell-value a:hover {
  color: var(--themePrimary);
  text-decoration: underline;
}
